if ($('#prod').length){
    const firebaseConfig = {
        apiKey: "AIzaSyBZ7tLf4AXQP9P4aS2nftEJCUi2dtHWTKU",
        authDomain: "stapro-system.firebaseapp.com",
        projectId: "stapro-system",
        storageBucket: "stapro-system.appspot.com",
        messagingSenderId: "457557617228",
        appId: "1:457557617228:web:65647df7ef62fddf2462e9",
        measurementId: "G-WHVHKBKGZ8"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

}else if ($('#stag').length){
    const firebaseConfig = {
        apiKey: "AIzaSyDlHsbiEfmCQxNUCxYYk6e_QZ9WvNvyGOk",
        authDomain: "stapro-system-stg.firebaseapp.com",
        projectId: "stapro-system-stg",
        storageBucket: "stapro-system-stg.appspot.com",
        messagingSenderId: "1079005715355",
        appId: "1:1079005715355:web:a5be0809ad238b0da9df3e"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
}
export default firebase



